@import "https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&family=Rubik:wght@300;400&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: DM Sans, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.left-\[22px\] {
  left: 22px;
}

.right-8 {
  right: 2rem;
}

.right-\[22px\] {
  right: 22px;
}

.top-\[-490px\] {
  top: -490px;
}

.top-\[108px\] {
  top: 108px;
}

.top-\[55\%\] {
  top: 55%;
}

.top-\[85px\] {
  top: 85px;
}

.z-\[1\] {
  z-index: 1;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-\[24px\] {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-\[3px\] {
  margin-top: 3px;
  margin-bottom: 3px;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[390px\] {
  height: 390px;
}

.h-\[50px\] {
  height: 50px;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-\[380px\] {
  min-height: 380px;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[390px\] {
  width: 390px;
}

.w-\[420px\] {
  width: 420px;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.min-w-\[128px\] {
  min-width: 128px;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.flex-1 {
  flex: 1;
}

.translate-y-\[-55\%\] {
  --tw-translate-y: -55%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[14px\] {
  border-radius: 14px;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-\[\#CCCCCC\] {
  --tw-border-opacity: 1;
  border-color: rgb(204 204 204 / var(--tw-border-opacity));
}

.border-b-white {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-\[\#231F20\] {
  --tw-bg-opacity: 1;
  background-color: rgb(35 31 32 / var(--tw-bg-opacity));
}

.bg-\[\#CCCCCC\] {
  --tw-bg-opacity: 1;
  background-color: rgb(204 204 204 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-primOrange {
  --tw-bg-opacity: 1;
  background-color: rgb(242 156 56 / var(--tw-bg-opacity));
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-center {
  object-position: center;
}

.p-2 {
  padding: .5rem;
}

.p-\[10px\] {
  padding: 10px;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-\[100px\] {
  padding-bottom: 100px;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-\[15px\] {
  padding-top: 15px;
}

.text-center {
  text-align: center;
}

.align-text-top {
  vertical-align: text-top;
}

.font-Rubik {
  font-family: Rubik, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[35px\] {
  font-size: 35px;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.text-\[\#242424\] {
  --tw-text-opacity: 1;
  color: rgb(36 36 36 / var(--tw-text-opacity));
}

.text-\[\#333333\] {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-primOrange {
  --tw-text-opacity: 1;
  color: rgb(242 156 56 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.no-underline {
  text-decoration-line: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-90 {
  opacity: .9;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-500 {
  transition-duration: .5s;
}

.duration-\[1000ms\] {
  transition-duration: 1s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.navitem {
  cursor: pointer;
  position: relative;
}

.navitem:hover {
  color: #fff;
}

.navitem:before {
  content: "";
  background-color: #fff;
  width: 0;
  height: 2px;
  transition: width .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navitem:hover:before {
  color: #fff;
  width: 100%;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:h-\[350px\] {
    height: 350px;
  }

  .sm\:w-\[30\%\] {
    width: 30%;
  }

  .sm\:w-\[70\%\] {
    width: 70%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }
}

@media (width >= 641px) {
  .min-\[641px\]\:h-\[335px\] {
    height: 335px;
  }

  .min-\[641px\]\:w-\[750px\] {
    width: 750px;
  }
}

@media (width >= 768px) {
  .md\:static {
    position: static;
  }

  .md\:z-auto {
    z-index: auto;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[400px\] {
    height: 400px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .md\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .md\:px-\[20px\] {
    padding-left: 20px;
    padding-right: 20px;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:font-thin {
    font-weight: 100;
  }

  .md\:opacity-100 {
    opacity: 1;
  }
}

@media (width >= 1024px) {
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:h-\[450px\] {
    height: 450px;
  }

  .lg\:w-\[1010px\] {
    width: 1010px;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .lg\:text-justify {
    text-align: justify;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .lg\:transition-all {
    transition-property: all;
    transition-duration: .15s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:duration-200 {
    transition-duration: .2s;
  }

  .lg\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }

  .lg\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media (width >= 1240px) {
  .min-\[1240px\]\:w-full {
    width: 100%;
  }

  .min-\[1240px\]\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .min-\[1240px\]\:whitespace-nowrap {
    white-space: nowrap;
  }
}

/*# sourceMappingURL=index.e58b9e68.css.map */
